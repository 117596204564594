import React, { createContext, useContext, useEffect, useState } from "react";
import {
  BookingClient,
  BookingSimpleDto,
  BookingStatus,
  IConfig,
  MessageClient,
  PaymentStatus,
  RentMyUserDetailedDto,
  TermsClient,
  UserClient,
} from "../api/rentMyApi";
import { useEnv } from "./env.context";
import { useAuth0 } from "@auth0/auth0-react";
import { termsOfServiceType } from "../components/terms/TermsRedirect";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { differenceInCalendarDays, isAfter, isBefore } from "date-fns";
import { sendUserLoginEvent } from "../api/analytics";
const UserContext = createContext({
  user: new RentMyUserDetailedDto(),
  updateUser: (value: RentMyUserDetailedDto) => {},
  countUnreadMessages: () => {},
  unreadMessageCount: 0,
  hasAcceptedTerms: false,
  updateAcceptedTerms: (value: boolean) => {},
  hasSetTermsStatus: false,
  bookingNotifications: false,
  rentalNotifications: false,
  isCurrentUserFetched: false,
  refetchCurrentUser: async () => {},
});

interface UserContextProviderProps {
  children: React.ReactNode;
}
const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser] = useState(new RentMyUserDetailedDto());
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  const [bookingNotifications, setBookingNotifications] =
    useState<boolean>(false);
  const [rentalNotifications, setRentalNotifications] =
    useState<boolean>(false);
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user: auth0User,
  } = useAuth0();

  const [userClient, setUserClient] = useState<UserClient>();
  const [messageClient, setMessageClient] = useState<MessageClient>();
  const [termsClient, setTermsClient] = useState<TermsClient>();
  const [bookingClient, setBookingClient] = useState<BookingClient>();

  const [hasSetTermsStatus, setHasSetTermsStatus] = useState<boolean>(false);
  const [hasAcceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const [messageClientInitialised, setMessageClientInitialised] =
    useState<boolean>(false);
  const [termsClientInitialised, setTermsClientInitialised] =
    useState<boolean>(false);
  const [userClientInitialised, setUserClientInitialised] =
    useState<boolean>(false);
  const [bookingClientInitialised, setBookingClientInitialised] =
    useState<boolean>(false);

  const [isCurrentUserFetched, setIsCurrentUserFetched] = useState(false);

  const { apiServerUrl } = useEnv();
  const { t } = useTranslation();

  useEffect(() => {
    async function initClient() {
      try {
        const token = await getAccessTokenSilently();
        setUserClient(new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT));
        setTermsClient(new TermsClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT));
        setMessageClient(new MessageClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT));
        setBookingClient(new BookingClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT));

        setUserClientInitialised(true);
        setMessageClientInitialised(true);
        setTermsClientInitialised(true);
        setBookingClientInitialised(true);
      } catch (e) {
        console.log(e);
      }
    }

    try {
      if (!isLoading && isAuthenticated && auth0User?.email_verified)
        initClient();
    } catch (e) {
      console.log("caught out of scope top function");
      console.log(e);
    }
  }, [isAuthenticated, isLoading, auth0User]);

  const refetchCurrentUser = async () => {
    if (userClientInitialised && userClient) {
      const localUser = await userClient.current(false, false);
      setUser(localUser);
      setIsCurrentUserFetched(true);
    }
  };

  useEffect(() => {
    refetchCurrentUser();
  }, [userClient, userClientInitialised]);


  const setUsersTermsOfServiceStatus = async () => {
    // console.log("setting terms of service?")
    // console.log("Terms client in TOS", termsClient)
    if (termsClientInitialised) {
      // console.log("Calling terms client")
      try {
        const x = await termsClient!.accepted2(termsOfServiceType);
         console.log("RESPONDED WITH X")
         console.log(x)

        let justLoggedIn = localStorage.getItem("justLoggedIn") || "false";

        // //send facebook login event
        if (justLoggedIn === "true" && x) {
          // console.log("sending login event...")
          const accountID = x.user.id;
          const userLocation = x.user.location ?? "N/A";
          const currentDate = new Date();
          const date = currentDate.toDateString();
          const time = currentDate.toTimeString();
          const isMobile = /Mobile/i.test(navigator.userAgent);
          const device = isMobile ? "Mobile" : "Desktop";
          sendUserLoginEvent(accountID, date, time, userLocation, device);
          justLoggedIn = "false";
          localStorage.setItem("justLoggedIn", justLoggedIn);
        }

        if (!x) {
          // setAcceptedTerms(true);
          setHasSetTermsStatus(true);
        } else {
          // setAcceptedTerms(false);
          setHasSetTermsStatus(true);
        }
      } catch (error) {
        console.log("An error occurred in setUsersTermsOfServiceStatus");
        console.log(error);
        toast.error(t("terms_err_notification"));
      }
    }
  };

  const countUnreadMessages = async () => {
    try {
      if (messageClientInitialised && messageClient) {
        const data = await messageClient.conversations();
        const filteredData = data.filter(
          (conversationMessages) => conversationMessages.users?.length
        );
        let unreadMessagesCount = 0;
        for (const dto of filteredData) {
          if (dto.unreadMessages && dto.users?.length) unreadMessagesCount += 1;
        }
        setUnreadMessageCount(unreadMessagesCount);
      }
    } catch (error) {
      console.log("An error occurred in countUnreadMessages");
      toast.error(t("account_conversations_loading_error"));
      console.log(error);
    }
  };

  const checkForNotifications = (
    booking: BookingSimpleDto,
    isSharerView: boolean
  ): boolean => {
    const { bookingStatus, startDate, endDate } = booking;
    const startOfBooking = new Date(startDate || "");
    const endOfBooking = new Date(endDate || "");
    const bookingWasCancelled = bookingStatus === BookingStatus["Cancelled"];
    const bookingIsRequested = bookingStatus === BookingStatus["Request"];
    const bookingIsCompleted = bookingStatus === BookingStatus["Completed"];
    const bookingIsAccepted =
      bookingStatus === BookingStatus["BookingAccepted"];
    const bookingIsOngoing = bookingStatus === BookingStatus["Ongoing"];
    const bookingPeriodHasStarted = isBefore(startOfBooking, new Date());
    const bookingPeriodHasEnded = isAfter(new Date(), endOfBooking);
    const bookingCanBeAccepted = bookingIsRequested && isSharerView;
    const canLeaveReview =
      !isSharerView && bookingIsCompleted && !booking.isReviewed;
    const canPay =
      !isSharerView &&
      !bookingWasCancelled &&
      !bookingIsRequested &&
      (booking.paymentStatus === PaymentStatus.Failed ||
        booking.paymentStatus === PaymentStatus.Unauthorised);

    const setBookingStatus = () => {
      const bookingCanBeAccepted = bookingIsRequested && isSharerView;

      if (bookingWasCancelled) return "Cancelled";

      if (bookingIsRequested) {
        if (isSharerView) {
          return "Accept or reject this request";
        } else {
          return "Awaiting response from sharer";
        }
      }

      if (
        booking.paymentStatus === PaymentStatus.Failed ||
        booking.paymentStatus === PaymentStatus.Unauthorised
      ) {
        if (isSharerView) {
          return "Awaiting payment";
        } else {
          return "Payment required";
        }
      }

      if (bookingIsCompleted) return "Completed";

      if (bookingCanBeAccepted) {
        if (isSharerView) {
          return "Accept or reject";
        } else {
          return "Awaiting response from sharer";
        }
      }

      if (bookingIsAccepted) {
        if (!bookingPeriodHasStarted) {
          const daysBeforeStart = differenceInCalendarDays(
            booking.startDate!,
            new Date()
          );
          if (daysBeforeStart)
            return `Starting in ${daysBeforeStart} day${
              daysBeforeStart > 1 ? "s" : ""
            }`;
        } else {
          if (isSharerView) {
            return "Awaiting pick-up.";
          } else {
            return "Pick-up required";
          }
        }
      }
      if (bookingIsOngoing) {
        const daysBeforeEnd = differenceInCalendarDays(
          booking.endDate!,
          new Date()
        );
        if (bookingPeriodHasEnded) {
          if (daysBeforeEnd === 0) {
            if (isSharerView) {
              return "Return required";
            } else {
              return "Return required";
            }
          } else {
            if (isSharerView) {
              return "Return required";
            } else {
              return "Awaiting return confirmation";
            }
          }
        } else {
          if (daysBeforeEnd === 0) {
            return "Ends today";
          } else {
            return `Ends in ${daysBeforeEnd} day${
              daysBeforeEnd > 1 ? "s" : ""
            }`;
          }
        }
      }
    };

    const bookingStatusString = setBookingStatus();

    const canPickUpItem = bookingStatusString === "Pick-up required";
    const canConfirmDropoff =
      isSharerView &&
      (bookingStatusString === "Return required" ||
        bookingStatusString === "Ongoing");

    const menuItems = [
      canLeaveReview,
      canPay,
      bookingCanBeAccepted,
      canPickUpItem,
      canConfirmDropoff,
    ];

    return menuItems.some((item) => item);
  };

  const countBookingNotifications = async () => {
    try {
      const [bookings, rentals] = await Promise.all([
        bookingClient?.booking2(true, false, true, undefined, false, 30, 1),
        bookingClient?.booking2(true, false, false, undefined, false, 30, 1),
      ]);

      if (bookings?.data) {
        const hasBookingNotifications = bookings.data.some(
          (booking) =>
            booking.bookingStatus !== BookingStatus.Completed &&
            booking.bookingStatus !== BookingStatus.Cancelled &&
            checkForNotifications(booking, true)
        );
        setBookingNotifications(hasBookingNotifications);
      }

      if (rentals?.data) {
        const hasRentalNotifications = rentals.data.some(
          (rental) =>
            rental.bookingStatus !== BookingStatus.Completed &&
            rental.bookingStatus !== BookingStatus.Cancelled &&
            checkForNotifications(rental, false)
        );
        setRentalNotifications(hasRentalNotifications);
      }
    } catch (error) {
      console.log("An error occurred in countBookingNotifications");
      toast.error(t("account_booking_err_notifications"));
      console.log(error);
    }
  };

  useEffect(() => {
    if (isCurrentUserFetched && termsClientInitialised) {
      setUsersTermsOfServiceStatus();
    }
  }, [isCurrentUserFetched, termsClientInitialised]);



  useEffect(() => {
    if (isCurrentUserFetched && bookingClientInitialised) {
      countBookingNotifications();
    }
  }, [isCurrentUserFetched, bookingClientInitialised]);

  useEffect(() => {
    if (isCurrentUserFetched && bookingClientInitialised) {
      countUnreadMessages();
    }
  }, [isCurrentUserFetched, messageClientInitialised]);

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser: (value) => {
          setUser(value);
        },
        unreadMessageCount,
        countUnreadMessages,
        hasAcceptedTerms,
        updateAcceptedTerms: (value) => {
          setAcceptedTerms(value);
        },
        hasSetTermsStatus,
        bookingNotifications,
        rentalNotifications,
        isCurrentUserFetched,
        refetchCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUserContext was used outside of its Provider");
  }

  return context;
};

export { UserContext, UserContextProvider, useUserContext };
