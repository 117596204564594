import { Button } from "reactstrap";
import { useEffect, useState } from "react";
import {
  IConfig,
  ItemClient,
  ItemCreateDto,
  TermsClient,
  TermsSimpleDto,
  TermsSimpleDtoPaginationDto,
} from "../../api/rentMyApi";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { LoadingFullScreen } from "../common/LoadingFullScreen";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Range } from "react-date-range";
import {
  idConst,
  termsOfServiceName,
  termsOfServiceType,
  toBookingConfirmationUrl,
} from "./TermsRedirect";
import { userLoginAnalytics, userSignupAnalytics } from "../../api/analytics";
import { useUserContext } from "../../context/UserContext";

export function Terms({
  itemListingPayload,
  itemBookingPayload,
  isLogin,
  withAcceptButton,
}: {
  itemListingPayload: ItemCreateDto | undefined;
  itemBookingPayload: Range | undefined;
  isLogin: boolean | undefined;
  withAcceptButton: boolean | undefined;
}) {
  const { t } = useTranslation();
  const { getAccessTokenSilently, logout, isAuthenticated } =
    useAuth0();
  const [termsDTO, setTermsDTO] = useState<TermsSimpleDto>();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const { updateAcceptedTerms } = useUserContext();
    const [loading, setLoading] = useState(false)

  async function getClient () {
    const token = await getAccessTokenSilently({ignoreCache: true});
    return new TermsClient(
      new IConfig(token),
      process.env.REACT_APP_API_ENDPOINT
    );

  }

  function onDenyClick(e) {
    e.preventDefault();
    setLoading(true);
    logout({ returnTo: window.location.origin });
  }


  async function onAcceptClickAsync(e) {
    e.preventDefault();
    setLoading(true);
    const termId = termsDTO!.id;
    const client = await getClient();
    client.accept(termId).then(() => {
        updateAcceptedTerms(true);
        console.log("Accepted terms...")
        setLoading(false);
    }).catch((err) => {
        console.log("err : ", err)
        setLoading(false);
    })
  }


  async function populateTermsPage() {
    const client = await getClient();
    client
      .terms2()
      .then((result: TermsSimpleDtoPaginationDto) => {
        if (!result.data) {
          toast.error("no response on terms call, please contact an admin");
          return;
        }
        const termsOfServiceTermDto = result.data.find(
          (x) => x.name === termsOfServiceName
        );

        if (termsOfServiceTermDto === undefined) {
          toast.error(
            "Could not find a term with the name" +
              termsOfServiceName +
              ". Please contact admin"
          );
          return;
        }

        setTermsDTO(termsOfServiceTermDto);
        setHasLoaded(true);
      })
      .catch((error) => {
        console.log("silently failed terms call...")
      });
  }

  useEffect(() => {
    if(!isAuthenticated) return () => {}
    populateTermsPage();
  }, [isAuthenticated])


  return (
    <>
      {!hasLoaded && <LoadingFullScreen />}

      {hasLoaded && (
        <>
          {withAcceptButton && termsDTO && (
            <>
              <div
                className={`accept-terms-banner ${loading ? 'softloading': ""}`}
              >
                <h2>{t("terms_accept_label")}</h2>
                <div className="button-section">
                  <a href="#_" className="btn btn-outline-primary" onClick={onDenyClick}>
                    {t("terms_deny")}
                  </a>
                  <a href="#_" className="btn btn-primary" onClick={onAcceptClickAsync}>
                    {t("terms_accept")}
                  </a>
                </div>
              </div>
              <div className="fixed-bottom-accept-banner-spacing-standin"></div>
            </>
          )}
          {termsDTO && (
            <div
              className={`bottom-padding-50 ${loading ? 'softloading': ""}`}
              dangerouslySetInnerHTML={{ __html: termsDTO.content! }}
            />
          )}
        </>
      )}
    </>
  );
}
