import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { faEnvelope, faLock, faSms, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { IConfig, RentMyUserDetailedDto, UserClient } from '../../api/rentMyApi';

import AccountPagesSidebar from '../../components/account/AccountPagesSidebar';
import SweetAlert from 'react-bootstrap-sweetalert';
import ToggleSwitch from '../../components/common/ToggleSwitch';
import { useUserContext } from '../../context/UserContext';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export function AccountSettingsPage() {
  const { t } = useTranslation();
  const { getAccessTokenSilently, logout } = useAuth0();
  const { user } = useUserContext();

  const [whatsAppNotificationsToggle, setWhatsAppNotificationsToggle] = useState(false);
  const [smsNotificationsToggle, setSmsNotificationsToggle] = useState(false);
  const [emailNotificationsToggle, setEmailNotificationsToggle] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userClient, setUserClient] = useState<UserClient>(
    new UserClient(new IConfig('notoken'), process.env.REACT_APP_API_ENDPOINT),
  );

  useEffect(() => {
    async function setUser() {
      const token = await getAccessTokenSilently();
      const userClient = new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      setUserClient(userClient);
      const localUser = await userClient.current(false, false);
      setWhatsAppNotificationsToggle(localUser.receiveWhatsappNotifications);
      setSmsNotificationsToggle(localUser.receiveTextNotifications);
      setEmailNotificationsToggle(localUser.receiveEmailsNotifications);
    }

    setUser();
  }, []);

  function handleRemoveAccount() {
    setIsDeleteModalOpen(true);
  }

  async function deleteAccountAction() {
    try {
      await userClient.userDelete(user.id);
      setIsDeleteModalOpen(false);
      logout();
    } catch (error) {
      setIsDeleteModalOpen(false);
      toast.error("There was an error. Please try again later.")
    }
  }

  function cancelDeleteAccount() {
    setIsDeleteModalOpen(false);
  }

  function handleSendPasswordReset() {
    userClient
      .passwordReset()
      .then((res) => {
        toast.success(t('account_settings_password_rest_successful'));
      })
      .catch((e) => {
        toast.error(t('account_settings_password_rest_failure'));
      });
  }

  const notificationMap: { [key: string]: string } = {
    Email: 'receiveEmailsNotifications',
    Phone: 'receivePhoneNotifications',
    Post: 'receivePostNotifications',
    SMS: 'receiveTextNotifications',
    WhatsApp: 'receiveWhatsappNotifications',
  };

  const updateUserNotificationSettings = async (
    text: string,
    toggleValue: boolean,
    setToggleStatus: any,
  ): Promise<void> => {
    setToggleStatus(toggleValue);
    userClient
      .update26(undefined, {
        [notificationMap[text]]: toggleValue.toString(),
      })
      .then((response) => {
        toast.success(`${text} notification updated successfully`);
        // updateUser({...user, [notificationMap[text]]: toggleValue})
      })
      .catch((err) => {
        setToggleStatus(!toggleValue);
        toast.error(`${text} notification updated failed`);
      });
  };

  const accountButton = (icon: IconProp, title: string, buttonText: string, onClick: any) => (
    <div className="flex-row justify-content-between flex-display">
      <div className="flex-row align-items-center pt-2 pb-2 flex-display">
        <span className="py-3 px-3 account-settings-font-wrapper">
          <FontAwesomeIcon icon={icon} className="text-lg" />
        </span>
        <h3 className="mb-0 ml-2">{title}</h3>
      </div>
      <div className="flex-row align-items-center pt-2 pb-2 flex-display">
        <Button color="primary" type="button" outline className="profile-section-button" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  );

  const notificationToggle = (icon: IconProp, title: string, toggleStatus: boolean, setToggleStatus: any) => (
    <div className="flex-row justify-content-between flex-display">
      <div className="flex-row align-items-center pt-2 pb-2 flex-display">
        <span className="py-3 px-3 account-settings-font-wrapper">
          <FontAwesomeIcon icon={icon} className="text-lg" />
        </span>
        <h3 className="mb-0 ml-2">{title}</h3>
      </div>
      <div className="flex-row align-items-center pt-2 pb-2 flex-display">
        <ToggleSwitch
          disabled={false}
          checked={toggleStatus}
          onChange={() => updateUserNotificationSettings(title, !toggleStatus, setToggleStatus)}
          text={''}
          size={'default'}
        />
      </div>
    </div>
  );

  return (
    <Container className="mt-4 account-settings-container">
      <Row>
        <Col md={4} lg={4} xl={3} className="hide-on-mobile hide-on-tablet">
          <AccountPagesSidebar />
        </Col>
        <Col md={8} lg={8} xl={9} className="auto-margin">
          <Card>
            <CardBody className="flex-column">
              <>
                <div>
                  <h2>{t('account_settings_header')}</h2>
                  <hr className="mt-3 mb-3" />
                </div>

                {accountButton(faLock, t('account_settings_password_reset'), 'Reset', handleSendPasswordReset)}
                {accountButton(faTrash, t('account_settings_remove_account'), 'Delete', handleRemoveAccount)}

                <div>
                  <br />
                  <h2>Notifications</h2>
                  <hr className="mt-3 mb-3" />
                </div>
                {notificationToggle(faEnvelope, 'Email', emailNotificationsToggle, setEmailNotificationsToggle)}
                {notificationToggle(faSms, 'SMS', smsNotificationsToggle, setSmsNotificationsToggle)}
                {notificationToggle(
                  faWhatsapp,
                  'WhatsApp',
                  whatsAppNotificationsToggle,
                  setWhatsAppNotificationsToggle,
                )}
              </>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isDeleteModalOpen && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={'Yes, delete!'}
          cancelBtnText={t('account_settings_cancel')}
          confirmBtnBsStyle="danger"
          title={t('account_settings_remove_acc_modal_title')}
          onConfirm={() => deleteAccountAction()}
          onCancel={() => cancelDeleteAccount()}
        ></SweetAlert>
      )}
    </Container>
  );
}
