import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  faCircleXmark,
  faLocationDot,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import debounce from "lodash/debounce";
import { SelectedCategory } from "../../models/interfaces/SelectedCategory";
import {
  CategoryClient,
  CategorySimpleDtoPaginationDto,
  IConfig,
} from "../../api/rentMyApi";

import Autocomplete from "react-google-autocomplete";
import PlaceResult = google.maps.places.PlaceResult;

interface WhatWhereSearchProps {
  searchTerm?: string;
  existingCategoryName?: string;
  existingCategoryId?: string;
  where?: string;
  lat?: number;
  lng?: number;
  homepage?: boolean;
}
export default function WhatWhereSearch({
  searchTerm = "",
  existingCategoryName = "",
  existingCategoryId = "",
  where = "",
  lat = 0,
  lng = 0,
  homepage = false,
}: WhatWhereSearchProps) {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);

  const whatSearchTerm = "what";
  const [categoryClient] = useState<CategoryClient>(
    new CategoryClient(
      new IConfig("notoken"),
      process.env.REACT_APP_API_ENDPOINT
    )
  );
  const [selectedCategory, setSelectedCategory] = useState<
    | {
        name: string;
        id: string;
        isSelected: boolean;
      }
    | undefined
  >(
    existingCategoryName && existingCategoryId
      ? {
          name: existingCategoryName,
          id: existingCategoryId,
          isSelected: true,
        }
      : undefined
  );
  const [options, setOptions] = useState<SelectedCategory[]>([]);

  const [inputValue, setInputValue] = useState<string | undefined>(
    searchTerm ? searchTerm : undefined
  );
  const [categoryId, setCategoryId] = useState<string | undefined>(
    existingCategoryId || undefined
  );

  const [width, setWidth] = useState<number>(window.innerWidth);

  const [selectedPlace, setSelectedPlace] = useState<PlaceResult | undefined>();
  const [preventFormSubmit, setPreventFormSubmit] = useState(false);

  const isMobile = width <= 768;
  const isMiddleScreen = width <= 1001;

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

  const locationRef = useRef<HTMLInputElement>(null);

  const populateWhatIfPresentInUrl = (): string | undefined => {
    if (searchParams.get(whatSearchTerm)) {
      return searchParams.get(whatSearchTerm)!;
    }
    return undefined;
  };

  const [whatAreYouLookingFor, setWhatAreYouLookingFor] = useState<
    string | undefined
  >(populateWhatIfPresentInUrl());

  useEffect(() => {
    setInputValue(searchTerm ? searchTerm : undefined);
  }, [searchTerm]);

  useEffect(() => {
    if (existingCategoryId && existingCategoryName) {
      setCategoryId(existingCategoryId);
      setSelectedCategory({
        name: existingCategoryName,
        id: existingCategoryId,
        isSelected: true,
      });
    } else setSelectedCategory(undefined);
  }, [existingCategoryId, existingCategoryName]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--WhatWhereSearchMinWidth",
      isMobile ? "180px" : isMiddleScreen ? "260px" : "260px"
    );
    document.documentElement.style.setProperty(
      "--WhatWhereSearchMaxWidth",
      isMobile ? "200px" : isMiddleScreen ? "280px" : "280px"
    );

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    categoryClient
      .category2(true, true)
      .then((response: CategorySimpleDtoPaginationDto) => {
        if (response && response.data) {
          const categoriesInfo: SelectedCategory[] = response.data.map(
            (thing) => ({
              name: thing.name || "",
              id: thing.id || "",
              isSelected: false,
            })
          );
          categoriesInfo.sort((a, b) => (a.name < b.name ? -1 : 1));
          setOptions([
            {
              name: "All",
              id: "",
              isSelected: false,
            },
            ...categoriesInfo,
          ]);
        }
      })
      .catch((e) => {
        // console.log("Error at WhatWhereSearchBarDesktop.tsx")
        // console.log(e)
      });
  }, []);

  const getAsyncOptions = (inputValue: string) =>
    new Promise<SelectedCategory[]>((resolve) => {
      if (!inputValue) return;
      !whatAreYouLookingFor && setWhatAreYouLookingFor(inputValue!);
      whatAreYouLookingFor && setInputValue(inputValue!);

      const inputLength = inputValue.length;
      const rootOnly = !inputLength;

      categoryClient
        .category2(true, rootOnly, undefined, inputValue)
        .then((response: CategorySimpleDtoPaginationDto) => {
          if (response.data) {
            const categoriesInfo: SelectedCategory[] = response.data.map(
              (thing) => ({
                name: thing.name || "",
                id: thing.id || "",
                isSelected: false,
              })
            );
            categoriesInfo.sort((a, b) => {
              if (inputLength) {
                const aStartsSearchTerm =
                  a.name.slice(0, inputLength).toLowerCase() ===
                  inputValue.toLowerCase();
                const bStartsSearchTerm =
                  b.name.slice(0, inputLength).toLowerCase() ===
                  inputValue.toLowerCase();

                if (aStartsSearchTerm && bStartsSearchTerm) {
                  return 0;
                } else if (aStartsSearchTerm) {
                  return -1;
                } else if (bStartsSearchTerm) {
                  return 1;
                }
              }
              return a.name < b.name ? -1 : 1;
            });
            resolve(categoriesInfo);
          } else {
            console.error("valid response but no data field was returned");
          }
        })
        .catch(() => {
          console.error("api call error");
          resolve([]);
        });
    });

  const loadCategories = useCallback(
    debounce((inputText) => {
      getAsyncOptions(inputText).then((options) => {
        setOptions([
          {
            name: "All",
            id: "",
            isSelected: false,
          },
          ...options,
        ]);
      });
    }, 300),
    []
  );

  useEffect(() => {
    loadCategories(inputValue);
  }, [inputValue, loadCategories]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (preventFormSubmit) {
      event.preventDefault();
      setPreventFormSubmit(false);
      return;
    }
    event.preventDefault();
    window.location.href = window.location.origin + generateSearchPath();
  };

  const generateSearchPath = () => {
    let newPath = "/rent";
    if (inputValue) {
      newPath += `/${inputValue}`;
    } else {
      newPath += "/all";
    }

    if (
      selectedPlace &&
      selectedPlace.geometry!.location!.lat() &&
      selectedPlace.geometry!.location!.lng()
    ) {
      newPath += `/${selectedPlace.formatted_address}`;
      newPath += `?lat=${selectedPlace.geometry!.location!.lat()}&lng=${selectedPlace.geometry!.location!.lng()}`;
    }
    return newPath;
  };
  const whatSection = () => {
    return (
      <>
        <FontAwesomeIcon
          className="mr-1"
          icon={faMagnifyingGlass}
          color={inputValue ? "#EA554A" : "#b3bac2"}
          fontSize="20px"
        />
        <div className="select">
          <input
            type="text"
            placeholder={
              isMiddleScreen
                ? t("homepage_what_are_you_looking_for_short")
                : t("homepage_what_are_you_looking_for")
              }
              value={inputValue ?? ""}
              onChange={(e) => setInputValue(e.target.value)}
              className="react-select what-where-search-custom react-select__control"
            />
          </div>
        </>
      );
    };

  const isTextChangeRatherThanCategorySelection = (action: string) => {
    return action === "input-change";
  };

  const clearLocationInput = () => {
    setSelectedPlace(undefined);
    locationRef!.current!.value = "";
  };

  const whereSection = () => {
    return (
      <>
        <FontAwesomeIcon
          className="mr-1"
          icon={faLocationDot}
          color={selectedPlace ? "#EA554A" : "#b3bac2"}
          fontSize="20px"
        />
        <Autocomplete
          ref={locationRef}
          onInput={(e: any) => {
            if (e.target.value.trim() === "") setSelectedPlace(undefined);
          }}
          className="form-control fit-within-mobile-view"
          aria-placeholder={
            selectedPlace
              ? where
              : isMiddleScreen
              ? t("homepage_where_you_want_it_short")
              : t("homepage_where_you_want_it")
          }
          options={{
            types: ["(cities)"],
            componentRestrictions: { country: "uk" },
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && !selectedPlace) {
              setPreventFormSubmit(true);
            }
          }}
          inputAutocompleteValue={selectedPlace?.formatted_address || ""}
          onPlaceSelected={(place) => setSelectedPlace(place)}
          apiKey={apiKey}
        />
         {!!selectedPlace && (
        <FontAwesomeIcon
          className="mr-1 clickable clear-text"
          icon={faCircleXmark}
          color="#b3bac2"
          fontSize="20px"
          onClick={clearLocationInput}
        />
      )}
    </>
  );
};

  const searchbarType = homepage
    ? "d-flex homepage-searchbar"
    : "d-flex searchbar";

  return (
    <form onSubmit={handleSubmit} className="search-bar">
      <div className={searchbarType}>
        <div className="whatsection d-flex align-items-center">
          {whatSection()}
        </div>
        <div className="wheresection d-flex align-items-center">
          {whereSection()}
        </div>
        <Button type="submit" className="btn btn-default search_btn">
          {t("landing_search_btn")}
        </Button>
      </div>
    </form>
  );
}
