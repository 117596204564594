import * as React from "react";
import { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import {
  BookingPricingDto,
  IConfig,
  ItemPublicDto,
  TermsClient,
} from "../../api/rentMyApi";
import { useTranslation } from "react-i18next";
import PoliciesSnippetWithModal from "./PoliciesSnippetWithModal";
import { Range } from "react-date-range";
import { DisplayPriceComponents } from "./DisplayPriceComponents";
import { toast } from "react-toastify";
import { useUserContext } from "../../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { idConst, toBookingConfirmationUrl } from "../terms/TermsRedirect";
import { useNavigate } from "react-router-dom";
import { sendAddToCartEvent } from "../../api/analytics";

export function ItemDetailedPriceSection({
  itemDetails,
  dateRangeState,
  setDateRangeState,
  bookingPriceDetails,
    externalLink = undefined,
    dailyDiscountTotal
}: {
  itemDetails: ItemPublicDto;
  dateRangeState: Range;
  setDateRangeState: React.Dispatch<Range>;
  bookingPriceDetails?: BookingPricingDto | undefined;
        externalLink?: string | undefined;
        dailyDiscountTotal?: number;
}) {
  const { t } = useTranslation();
  const { user: currentlyLoggedInUser } = useUserContext();
  const { isAuthenticated } =
    useAuth0();
  const navigate = useNavigate();
  const [payload, setPayload] = useState<Range>();


  const isDateRangeValid = () => {
    return (
      dateRangeState.startDate != null &&
      dateRangeState.endDate != null &&
      dateRangeState.startDate < dateRangeState.endDate
    );
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const goToConfirmationIfDatesSelected = () => {
    console.log("Is Authenticated: " + isAuthenticated);

    if (!dateRangeState.startDate || !dateRangeState.endDate) {
      toast.error(t("item_detailed_select_date_range"));
      return;
    }

    setPayload(dateRangeState);
    const accountID = currentlyLoggedInUser.id;
    const itemID = itemDetails.id;
    const itemValue = itemDetails.itemValue;

    sendAddToCartEvent(accountID, itemID, itemValue);
    navigate(
      toBookingConfirmationUrl(
        `?${idConst}=` + itemDetails.id,
        dateRangeState.startDate,
        dateRangeState.endDate
      )
    );
  };


  return (
    <div>
      {!externalLink && itemDetails && itemDetails.priceInfo && itemDetails.priceInfo.periods && (
        <DisplayPriceComponents
          itemDetails={itemDetails}
          dateRangeState={dateRangeState}
          setDateRangeState={setDateRangeState}
          wantDateSelectionAndMultiDayDiscount={true}
                  wantPriceBreakdown={true}
                  dailyDiscountTotal={dailyDiscountTotal }
          showDiscounts={true}
          showInsuranceTotal={true}
          insuranceRequired={itemDetails.requireInsurance}
          bookingPriceDetails={bookingPriceDetails}
          itemDetailedPage={true}
        />
      )}

      {isMobile ? (
        <>
          {/*{itemDetails && enableInsurance && rentalInsuranceSection()}*/}
          <Card>
            <CardBody>
              {(itemDetails.user
                ? itemDetails.user.id === currentlyLoggedInUser.id
                : false) && (
                <div className="mb-2 badge-warning-ours badge badge-pill">
                  {t("item_detailed_self_rent")}
                </div>
              )}

              <button
                disabled={
                  (itemDetails.user
                    ? itemDetails.user.id === currentlyLoggedInUser.id
                    : false) || !isDateRangeValid()
                }
                onClick={() => goToConfirmationIfDatesSelected()}
                type="button"
                className="btn btn-primary btn-lg btn-block"
              >
                {isDateRangeValid()
                  ? t("item_detailed_rent_me")
                  : t("please_select_valid_date_range")}
              </button>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <Card>
            <CardBody>
              {(itemDetails.user
                ? itemDetails.user.id === currentlyLoggedInUser.id
                : false) && (
                <div className="mb-2 badge-warning-ours badge badge-pill">
                  {t("item_detailed_self_rent")}
                </div>
              )}

              <button
                disabled={
                  (itemDetails.user
                    ? itemDetails.user.id === currentlyLoggedInUser.id
                    : false) || !isDateRangeValid()
                }
                onClick={() => goToConfirmationIfDatesSelected()}
                type="button"
                className="btn btn-primary btn-lg btn-block"
              >
                {isDateRangeValid()
                  ? t("item_detailed_rent_me")
                  : t("please_select_valid_date_range")}
              </button>
            </CardBody>
          </Card>
        </>
      )}

      {itemDetails && (
        <>
          <PoliciesSnippetWithModal
            cancellationPolicy={itemDetails.cancellationPolicy}
            insurance={
              itemDetails.insurancePlans &&
              itemDetails.insurancePlans.length > 0
            }
          />
        </>
      )}

    </div>
  );
}
