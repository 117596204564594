import { Modal, ModalFooter } from "reactstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function BasicModal({
  modalTitle,
  modalHeader,
  modalBody,
  modalState,
  toggleModal,
  modalFooter,
  headerClass = "",
  className = "", // Add className property with a default value of an empty string
  dismissable = true
}: {
  modalTitle?: string;
  modalBody: JSX.Element;
  modalHeader?: JSX.Element;
  modalState: boolean;
  toggleModal: any;
  modalFooter?: JSX.Element;
  headerClass?: string;
  className?: string; // Add className property to the type definition
  dismissable?: boolean;
}) {
  const _headerClass = "modal-header background-colour-off-white" + headerClass;
  const headerText = headerClass
    ? "modal-title basic-modal-header text-white"
    : "modal-title basic-modal-header";

    const checkCanToggle = () => {
        if(dismissable) {
            toggleModal()
        }
    }
  return (
    <>
      <Modal
        className={`basic-modal modal-dialog-centered ${className}`} // Use the className property here
        isOpen={modalState}
        toggle={checkCanToggle}
      >
        <div className={_headerClass}>
          {modalTitle && (
            <h5 className={`text-align-center ml-3 ${headerText}`}>
              {modalTitle}
            </h5>
          )}
          {
            dismissable && (
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={checkCanToggle}
              >
                <FontAwesomeIcon className="right-align" icon={faXmark} />
              </button>
            )
          }
         
        </div>
        {modalHeader && <div className="modal-search">{modalHeader}</div>}

        <div className="modal-body">{modalBody}</div>
        {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
      </Modal>
    </>
  );
}
