import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

import AccountPagesSidebar from "../../components/account/AccountPagesSidebar";
import { Wallet } from "../../components/account/Dashboard/Wallet";
import { useEnv } from "../../context/env.context";
import { useUserContext } from "../../context/UserContext";

import {
  FileClient,
  IConfig,
  ImageDto,
  IRentMyUserDetailedDto,
  RentMyUserDetailedDto,
  RentMyUserPublicDetailedDtoPaginationDto,
  UserClient,
  WalletDto,
} from "../../api/rentMyApi";
import { ReviewList } from "../../components/review/ReviewList";
import { useNavigate } from "react-router-dom";
import { Referrals } from "../../components/account/Dashboard/Referrals";
import { Profile } from "../../components/account/Dashboard/Profile";

export function AccountDashboardPage() {
  const { user, updateUser } = useUserContext();
  const searchParams = new URLSearchParams(window.location.search);

  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);

  const [userData, setUserData] = useState<IRentMyUserDetailedDto>(user);
  const [payoutModalOpen, setPayoutModalOpen] = useState<boolean>(false);
  const [referralsModalOpen, setReferralsModalOpen] = useState<boolean>(false);
  const [noProfileWithThatId, setNoProfileWithThatId] =
    useState<boolean>(false);
  const [wallet, setWallet] = useState<WalletDto | undefined>(undefined);
  const [userClient] = useState<UserClient>(
    new UserClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const fileUploadBanner = useRef() as React.MutableRefObject<HTMLInputElement>;
  const { apiServerUrl } = useEnv();

  enum UserImageType {
    PROFILE,
    BANNER,
  }

  const approvedFileTypes = ["image/jpg", "image/jpeg", "image/png", "image/webp"];

  function isApprovedFileType(userFileType: string): boolean {
    return approvedFileTypes.some(
      (approvedFileType) => userFileType === approvedFileType.replace(/\s/g, "")
    );
  }

  const CloseIcon = () => {
    return (
      <div
        onClick={() => setReferralsModalOpen(false)}
        className="cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 36 36"
        >
          <circle
            id="Ellipse_10"
            data-name="Ellipse 10"
            cx="18"
            cy="18"
            r="18"
            fill="#e1e1e1"
          />
          <g
            id="Group_202"
            data-name="Group 202"
            transform="translate(-779.5 -325)"
          >
            <line
              id="Line_31"
              data-name="Line 31"
              y2="29"
              transform="translate(787.247 332.747) rotate(-45)"
              fill="none"
              stroke="#000"
              strokeWidth="3"
            />
            <line
              id="Line_32"
              data-name="Line 32"
              x1="28.5"
              transform="translate(787.424 353.076) rotate(-45)"
              fill="none"
              stroke="#000"
              strokeWidth="3"
            />
          </g>
        </svg>
      </div>
    );
  };

  useEffect(() => {
    const populateUserData = async () => {
      const idConst = "id";

      if (searchParams.has(idConst)) {
        try {
          const response: RentMyUserPublicDetailedDtoPaginationDto =
            await userClient.detailed53(
              false,
              searchParams.get(idConst)!
            );
          const walletResponse: WalletDto = await userClient.wallet();
          setUserData(response as unknown as IRentMyUserDetailedDto);
          setWallet(walletResponse);
        } catch (e: any) {
          if (e.status === 404) {
            setNoProfileWithThatId(true);
            setUserData(user);
          }
          return;
        }
      } else {
        setUserData(user);
      }
    };

    populateUserData();
  }, [user.id]);

  async function handleUserPhotoUpload(e: any, imageType: UserImageType) {
    setIsImageUploading(true);

    const token = await getAccessTokenSilently();

    const imageData = e.target.files[0];

    const updated_image: File = new File([imageData], imageData.name);

    if (!isApprovedFileType(imageData.type)) {
      toast.error(t("image_upload_file_type_error"));
      setIsImageUploading(false);
      return;
    }

    const apiFileClient = await new FileClient(
      new IConfig(token),
      process.env.REACT_APP_API_ENDPOINT
    );

    apiFileClient
      .upload(updated_image.type, {
        fileName: updated_image.name,
        data: updated_image,
      })
      .then(async (res) => {
        const userContextValueClone = { ...user } as RentMyUserDetailedDto;

        if (imageType === UserImageType.PROFILE) {
          try {
            await handleProfilePhotoUpdateAsync(res, token);
          } catch (e) {
            toast.error(t("my_profile_assign_error"));
          }

          setUserData({ ...userData, profileImage: res as ImageDto });
          userContextValueClone.profileImage = res as ImageDto;
          toast.success(t("my_profile_img_upload_success"));
        } else if (imageType === UserImageType.BANNER) {
          try {
            await handleBannerPhotoUpdateAsync(res, token);
          } catch (e) {
            toast.error(t("my_profile_assign_error"));
            // console.log(e)
          }

          setUserData({ ...userData, bannerImage: res as ImageDto });
          userContextValueClone.bannerImage = res as ImageDto;
          toast.success(t("my_profile_banner_upload_success"));
        }

        updateUser(userContextValueClone);
      })
      .catch((e) => {
        toast.error(t("my_profile_upload_error"));
      })
      .finally(() => {
        setIsImageUploading(false);
      });
  }

  // DEVNOTE - For "handleProfilePhotoUpdateAsync" and "handleBannerPhotoUpdateAsync". The backend does not expect you to pass down a userId, this is only if an admin is changing your picture. UserId is gotten from the authorisation instead to tell who is the logged in person.
  async function handleProfilePhotoUpdateAsync(
    imageUpdateData: ImageDto,
    token: string
  ) {
    await new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT).changeProfileImage(
      imageUpdateData.id
    );
  }

  async function handleBannerPhotoUpdateAsync(
    imageUpdateData: ImageDto,
    token: string
  ) {
    await new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT).changeBannerImage(
      undefined,
      imageUpdateData.id
    );
  }

  const handleReferralsClick = async () => {
    // const referral = await referralClient.referral2()
    setReferralsModalOpen(true);
    // console.log(referral)
  };

  const BannerSection = () => (
    <Col lg={6}>
      <Card>
        <CardBody>
          <div className="flexy mb-3">
            <h2>{t("my_profile_banner")}</h2>
          </div>

          <div>
            <img
              className="account_profile_banner_picture"
              alt={"banner picture of " + userData.name}
              src={`${
                userData?.bannerImage
                  ? userData.bannerImage.compressedPath
                  : "assets/img/user-banner-img-placeholder.webp"
              }`}
            />
            <input
              type="file"
              ref={fileUploadBanner}
              defaultValue=""
              name="bannerPic"
              onChange={(e) => handleUserPhotoUpload(e, UserImageType.BANNER)}
              hidden
              accept="image/png, image/gif, image/jpeg, image/jpg, image/webp"
            />
          </div>
          <p className="banner-instructions">
            {t("my_profile_banner_instructions")}
          </p>
          <Button
            className="btn-icon btn-3 flex-right upload-image-button no-flex"
            color="primary"
            disabled={isImageUploading}
            type="button"
            onClick={() => {
              fileUploadBanner?.current?.click();
            }}
          >
            <span className="btn-inner--icon">
              <FontAwesomeIcon icon={faUpload} />
            </span>
            <span className="btn-inner--text">
              {t("my_profile_change_account_photo")}
            </span>
          </Button>
        </CardBody>
      </Card>
    </Col>
  );

  const Reviews = () => (
    <Col lg={12}>
      <Card>
        <CardBody>
          <div className="reviews">
            <div className="header-section">
              <h2>Latest Reviews</h2>
            </div>
          </div>
          <ReviewList
            hidden={false}
            itemId={""}
            userId={user.id}
            showProduct={true}
          ></ReviewList>
        </CardBody>
      </Card>
    </Col>
  );

  const PayoutModal = () => (
    <Modal isOpen={payoutModalOpen} className="wallet-modal" centered>
      <Card>
        <CardBody>
          <h2>
            Your payment is on it’s way and should be with you in 72 hours
          </h2>
          <Row className="button-row">
            <Button color="primary" onClick={() => setPayoutModalOpen(false)}>
              Okay
            </Button>
          </Row>
        </CardBody>
      </Card>
    </Modal>
  );

  const ReferralsModal = () => {
    if (!user.referredUsers || user.referredUsers.length === 0) {
      return (
        <Modal
          isOpen={referralsModalOpen}
          className="referrals-modal"
          centered
          toggle={() => setReferralsModalOpen(false)}
        >
          <Card className="card">
            <CardBody className="overflow-scroll-vertical">
              <p className="text-align-center">
                No users have used your referral code yet
              </p>
            </CardBody>
          </Card>
        </Modal>
      );
    }

    return (
      <Modal
        isOpen={referralsModalOpen}
        className="referrals-modal"
        centered
        toggle={() => setReferralsModalOpen(false)}
      >
        <Card className="card">
          <CardHeader>
            <Row className="header">
              <CloseIcon />
              <h2>Users signed up with your code</h2>
            </Row>
          </CardHeader>
          <CardBody className="overflow-scroll-vertical">
            <Row className="overflow-scroll-vertical">
              {user.referredUsers.map((refUser) => {
                const src = refUser.profileImage?.compressedPath
                  ? `${apiServerUrl}/api/v1/${refUser.profileImage.compressedPath}`
                  : "assets/img/profile-pic-placeholder.webp";
                return (
                  <Col xs={12} key={refUser.id}>
                    <Row>
                      <Col xs={3}>
                        <img
                          src={src}
                          alt="Profile"
                          className="rounded-circle referral-modal-image"
                        />
                      </Col>
                      <Col xs={9}>
                        <Row className={"text-align-center"}>
                          <Col xs={12}>
                            <h3 className="mt-3 mb-1">{refUser.name}</h3>
                          </Col>
                          <Col xs={12}>
                            <p>{refUser.joinedDate.toLocaleDateString()}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </Modal>
    );
  };

  return (
    <>
      <Container className="mt-4 account-settings-container">
        <Row>
          <Col md={4} lg={4} xl={3} className="hide-on-mobile hide-on-tablet">
            <AccountPagesSidebar />
          </Col>
          <Col md={8} lg={8} xl={9} className="auto-margin">
            <>
              {noProfileWithThatId && (
                <h1 className="center-text">No profile with that Id</h1>
              )}
              {!noProfileWithThatId && (
                <>
                  <Row>
                    <Wallet setPayoutModalOpen={setPayoutModalOpen} />

                    <Container className="flex-display">
                      <Row>
                        <Col lg={8}>
                          <Referrals
                            handleReferralsClick={handleReferralsClick}
                          />
                        </Col>
                        <Col lg={4}>
                          <Profile
                            isEditable={false}
                            handleUserPhotoUpload={() => console.log()}
                          />
                        </Col>
                      </Row>
                    </Container>

                    <Reviews />
                  </Row>
                </>
              )}
            </>
          </Col>
        </Row>
      </Container>
      <PayoutModal />
      <ReferralsModal />
    </>
  );
}
